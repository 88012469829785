import { graphql, Link } from 'gatsby';
import * as React from 'react';
import Layout from '../components/layout';
import PostList, { PostNode } from '../components/post-list';
import SEO, { SeoModel } from '../components/seo';

interface ArchivePageProps {
  location: Location,
  data: {
    allMarkdownRemark: {
      edges: ({node: PostNode})[]
    }
  },
  pageContext: {
    previousPagePath: string,
    nextPagePath: string
  }
}

export const articlePageQuery = graphql`
  query ArticlePageQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: {fields: {collection: {eq: "archive"}}},
      sort: {fields: frontmatter___date, order: DESC},
      skip: $skip
      limit: $limit) {
      edges {
        node {
          fields {
            slug
          }
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "DD MMMM YYYY", locale: "nl-NL")
            title
            author
            categories
            source_url
          }
        }
      }
    }
  }
`;

export default class ArticlePage extends React.Component<ArchivePageProps, {}> {

  public render() {
    const { allMarkdownRemark } = this.props.data;
    const seo: SeoModel = {
      title: 'Nieuwsarchief'
    };
    return (
      <Layout location={this.props.location}>
        <SEO model={seo} />
        <div className="mt-5" />
        <section id="archive" className={`bg-white text-left px-3 py-5`}>
          <h1 className="text-center my-5">Nieuwsarchief</h1>
          <div className="container">
            <PostList posts={allMarkdownRemark.edges} />
          </div>
        </section>
        <section className="pt-0">
          <div className="container px-3 d-flex justify-content-between">
            <Link className="px-3" to={this.props.pageContext.previousPagePath}>← Nieuwer</Link>
            <Link className="px-3" to={this.props.pageContext.nextPagePath}>Ouder →</Link>
          </div>
        </section>
      </Layout>
    );
  }
}

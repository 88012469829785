import { Link } from 'gatsby';
import * as React from 'react';
import styles from './post-list.module.scss';

export interface PostNode {
  excerpt: string,
  html: string,
  fields: {
    slug: string
  },
  frontmatter: {
    date: string,
    title: string
    categories: string[]
  }
}

interface PostListProps {
  posts: {node: PostNode}[],
  hideCategoryBadge?: boolean,
}

const PostList: React.SFC<PostListProps> = ({
  posts,
  hideCategoryBadge
}) => {
  return (
    <div className="d-flex flex-rows flex-wrap w-100">
      {posts.map(({node}) => (
        <div key={`article-${node.fields.slug}`} className="col-12 col-xl-6">
          <div className="article my-4">
            <h3 className={`${styles.postTitle}`}>
            { node.frontmatter.title.length > 75
                ? `${node.frontmatter.title.substring(0, 65 + node.frontmatter.title.substring(65).indexOf(' '))}...`
                : node.frontmatter.title
              }
            </h3>
            <div className={`d-flex flex-rows ${styles.postDetails}`}>
              <p className="text-muted">{node.frontmatter.date}</p>
              {!hideCategoryBadge && (
                <h5>
                  {node.frontmatter.categories.map(category => (
                    <Link key={category} className="badge badge-secondary ml-3" to={`/archive/category/${category}`}>
                      {category}
                    </Link>
                  ))}
                </h5>
              )}
            </div>
            <p className={`${styles.postContent}`}>
              {node.excerpt}
            </p>
            <p>
              <Link to={node.fields.slug}>Lees meer</Link>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default PostList;
